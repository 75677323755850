<template>
  <div class="wrapper wrapper-full-page">
    <div class="page-header login-page header-filter">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-7 ml-auto mr-auto">
            <form class="form" @submit.prevent="submitForm">
              <div class="card card-login">
                <div class="card-header card-header-primary text-center">
                  <div class="logo">
                    <img :src="imgLogo" alt="Boote" class="img" />
                  </div>
                </div>
                <div class="card-body">
                  <div class="bmd-form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="material-icons">email</i>
                        </span>
                      </div>
                      <input
                        class="form-control"
                        type="email"
                        placeholder="Email"
                        @input="updateEmail"
                        @focus="focusField('email')"
                        @blur="clearFocus"
                        required
                        autocomplete="email"
                        autofocus
                      />
                    </div>
                  </div>
                  <div class="bmd-form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="material-icons">password</i>
                        </span>
                      </div>
                      <input
                        class="form-control"
                        type="password"
                        placeholder="Password"
                        @input="updatePassword"
                        @focus="focusField('password')"
                        @blur="clearFocus"
                        required
                        autocomplete="current-password"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer justify-content-center flex-column">
                  <vue-button-spinner
                    class="btn btn-round btn-primary"
                    type="submit"
                    :status="status"
                    :isLoading="loading"
                    :disabled="loading"
                    @submit="submitForm"
                  >
                    {{ $t("auth.do_login") }}
                  </vue-button-spinner>
                  <bootstrap-alert />
                </div>
                <div class="card-footer justify-content-center flex-column ">
                  <router-link
                    :to="{ name: 'forgot-password' }"
                    active-class="btn btn-link btn-primary"
                    tag="a"
                  >
                    {{ $t("auth.reset.forgot_password") }}
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      status: "",
      activeField: ""
    };
  },
  props: {
    imgLogo: {
      type: String,
      default: require("../../../assets/logo/logo_large.png")
    }
  },
  computed: {
    ...mapGetters("Auth", ["loading"])
  },
  beforeDestroy() {
    this.resetState();
  },
  methods: {
    ...mapActions("Auth", [
      "login",
      "setEmail",
      "setPassword",
      "setLoading",
      "resetState"
    ]),
    updateEmail(e) {
      this.setEmail(e.target.value);
    },
    updatePassword(e) {
      this.setPassword(e.target.value);
    },
    submitForm() {
      this.login()
        .then(() => {
          this.$router.push({ name: "index" });
          this.$eventHub.$emit("login-success");
        })
        .catch(error => {
          this.status = "failed";
          _.delay(() => {
            this.status = "";
            this.setLoading(false);
          }, 3000);
        });
    },
    focusField(name) {
      this.activeField = name;
    },
    clearFocus() {
      this.activeField = "";
    }
  }
};
</script>

<style scoped>
.img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>